import React from "react";
import ContactForm from "./ContactForm";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { FaMap, FaMobile, FaMailBulk } from "react-icons/fa";

export default function Contact() {
  return (
    <section id="iletisim" className="w-full lg:rounded-t-lg pt-10 pb-10 mb-0 bg-gray-900">
      <h2 className="relative text-center uppercase font-bold text-[22px] text-white lg:text-[36px] mb-6">
        iletişim
      </h2>
      <div className="container mx-auto mt-2">
        <div className="flex flex-wrap items-center lg:gap-y-6 justify-center mb-10">
          <div className="flex flex-wrap items-center justify-center mb-4 gap-x-11 gap-y-2">
            <div className="contact-card">
              <Card className="mt-6 w-80 h-52 border-2 items-center p-10 rounded-sm shadow-md ">
                <CardHeader>
                  <span className="text-center font-bold text-[20px] text-green-600">
                    <FaMap />
                  </span>
                </CardHeader>
                <CardBody className="p-2">
                  <Typography className='gap-y-2' >
                    <h5 className="text-center font-bold">Adres</h5>
                    <p className="text-center">
                     Doğancı Mah. İnönü Caddesi No:1 D:201, 33600 Mut/Mersin
                    </p>
                  </Typography>
                </CardBody>
              </Card>
            </div>
            <div className="contact-card">
              <Card className="mt-6 w-80 h-52 border-2 items-center p-10 rounded-sm shadow-md ">
                <CardHeader>
                  <span className="text-center font-bold text-[28px] text-blue-500">
                    <FaMobile />
                  </span>
                </CardHeader>
                <CardBody className="p-2">
                  <Typography>
                    <h5 className="text-center font-bold">Telefon</h5>
                    <a className="text-center mt-1 btn" href="tel:+905534788678">
                    +90 (553) 478 8678
                    </a>
                  </Typography>
                </CardBody>
              </Card>
            </div>
            <div className="contact-card">
              <Card className="mt-6 w-80 h-52 border-2 items-center p-10 rounded-sm shadow-md  ">
                <CardHeader>
                  <span className="text-center font-bold text-[28px] text-red-600">
                    <FaMailBulk />
                  </span>
                </CardHeader>
                <CardBody className="p-2">
                  <Typography>
                    <h5 className="text-center font-bold">E-Posta</h5>
                    <a
                      className="text-center mt-1 btn"
                      href="mailto:tahirkoldemir@jeomut.com"
                    >
                      tahirkoldemir@jeomut.com
                    </a>
                  </Typography>
                </CardBody>
              </Card>
            </div>
          </div>
          <div className="flex flex-col mt-10 gap-y-12 lg:flex-row lg:flex-items-center lg:gap-x-12">
            <div className="map-vis rounded-lg w-80 h-96 bg-sky-700   lg:w-[600px] border-2">
            <iframe className="w-full h-full rounded-lg" title="jeomut-location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3201.302284822026!2d33.435116174901566!3d36.64317787653667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d9137aa4af8573%3A0xac570a1cbd4d39b6!2sJeomut-jeoloji%20muhendisi%20tahir%20koldemir!5e0!3m2!1str!2str!4v1692036072698!5m2!1str!2str" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="email-form bg-white rounded-lg border-2  w-80 lg:w-[400px] p-5">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
