import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const ContactForm = () => {
  const initialValues = {
    name: '',
    email: '',
    phone:'',
    subject: '',
    message: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('İsim alanı zorunludur'),
    email: Yup.string().email('Geçerli bir email adresi girin').required('Email alanı zorunludur'),
    phone: Yup.string().required('Telefon alanı zorunludur'),
    subject: Yup.string().required('Konu seçimi yapınız'),
    message: Yup.string().required('Mesaj alanı zorunludur'),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await axios.post('https://formspree.io/f/mkgozqkk', values);
      alert('Mesajınız başarıyla gönderildi');
      resetForm();
    } catch (error) {
      alert('Mesaj gönderilirken bir hata oluştu');
    }
    setSubmitting(false);
  };

  return (
    <div className='bg-white'>
      <h2 className='text-center font-bold mb-4'>İletişim Formu</h2>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form className='gap-y-10'>
            <div>
              <Field type="text" id="name" name="name" className="border-b-2 w-full p-1" placeholder="Adınız Soyadınız"/>
              <ErrorMessage name="name" component="div" className="error text-sm text-red-500" />
            </div>

            <div>
              <Field type="email" id="email" name="email" className="border-b-2 w-full p-1" placeholder="E-Posta" />
              <ErrorMessage name="email" component="div" className="error text-sm text-red-500" />
            </div>
            <div>
              <Field type="text" id="phone" name="phone" className="border-b-2 w-full p-1" placeholder="Telefon" />
              <ErrorMessage name="phone" component="div" className="error text-sm text-red-500" />
            </div>

            <div className='mt-4 mb-4'>
              <Field as="select" id="subject" name="subject" className="border-b-2 w-full p-1">
                <option value="">Konu Seçiniz</option>
                <option value="Sondaj">Sondaj</option>
                <option value="Hidrojeolojik Etüt">Hidrojeolojik Etüt</option>
                <option value="Derin Kuyu Arama">Derin Kuyu Arama</option>
                <option value="Derin Kuyu Kullanma">Derin Kuyu Kullanma</option>
                <option value="Zemin Etütü">Zemin Etütü</option>
                <option value="Zemin Güçlendirme">Zemin Güçlendirme</option>
              </Field>
              <ErrorMessage name="subject" component="div" className="error text-sm text-red-500" />
            </div>

            <div>
              <Field as="textarea" id="message" name="message" placeholder="Arazi İle İlgili Bütün Detayları Yazın. Konumu, Alanı vb. " className="border-2 w-full p-1" />
              <ErrorMessage name="message" component="div" className="error text-sm text-red-500" />
            </div>

            <button type="submit" className='bg-black text-white p-2 mt-3 w-full rounded-md' disabled={isSubmitting}>
              Gönder
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
